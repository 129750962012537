<template>
  <div>
    <svg
      @click="isOpen = !isOpen"
      class="w-1/6 cursor-pointer"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 50 50"
      xml:space="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <circle style="fill: #43b05c" cx="25" cy="25" r="25"></circle>
        <line
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          "
          x1="25"
          y1="13"
          x2="25"
          y2="38"
        ></line>
        <line
          style="
            fill: none;
            stroke: #ffffff;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          "
          x1="37.5"
          y1="25"
          x2="12.5"
          y2="25"
        ></line>
      </g>
    </svg>
    <div v-if="isOpen" class="p-4 bg-gray-100 rounded-lg shadow mt-3">
      <select
        v-model="selectedMeal"
        aria-label="Select meal type"
        class="block w-full p-2 mt-3 mb-3 bg-white border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
      >
        <option disabled value="">Select Meal</option>
        <option value="breakfast">Breakfast</option>
        <option value="lunch">Lunch</option>
        <option value="snack">Snack</option>
        <option value="dinner">Dinner</option>
        <option value="sides">Sides</option>
        <option value="desserts">Desserts</option>
      </select>
      <select
        v-model="selectedRecipe"
        aria-label="Select a recipe"
        class="block w-full p-2 mt-3 bg-white border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
      >
        <option disabled value="">Select Recipe</option>
        <option :value="recipe" v-for="recipe in userRecipes" :key="recipe.id">
          {{ recipe.title }}
        </option>
      </select>
      <button
        class="mx-auto block mt-4 w-1/3 btn btn-success text-white"
        @click.prevent="emitSelections"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRecipesStore } from '@/stores/useRecipesStore'
import { ref, onMounted, watch } from 'vue'

const recipesStore = useRecipesStore()
const userRecipes = ref([])

onMounted(async () => {
  userRecipes.value = recipesStore.userRecipes
})
watch(async () => {
  userRecipes.value = recipesStore.userRecipes
})

const isOpen = ref(false)
const selectedMeal = ref('')
const selectedRecipe = ref('')

const emit = defineEmits(['updatesSelectedRecipes'])

const emitSelections = () => {
  isOpen.value = false // Optionally close the dropdown after selecting
  ProgressEvent
  emit('updatesSelectedRecipes', selectedMeal.value, selectedRecipe.value)
}
</script>
