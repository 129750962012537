<template>
  <header class="bg-gray-800 text-white px-3">
    <nav
      class="container mx-auto flex justify-between items-center py-6 w-[80vw]"
      aria-label="Main navigation"
    >
      <router-link
        v-if="user"
        to="/recipes"
        class="self-center text-lg font-semibold text-white hover:text-gray-300"
        aria-label="Go to Recipe-Saver home page"
      >
        Recipe-Saver
      </router-link>
      <div>
        <span aria-live="polite">Your Access Code is: {{ user }}</span>
      </div>
      <!-- Display links based on authentication status -->
      <div class="flex gap-4">
        <router-link
          v-if="user"
          to="/saved-recipes"
          class="self-center text-white hover:text-gray-300"
          aria-label="Go to Saved Recipes"
        >
          Saved Recipes
        </router-link>
        <!-- <router-link
          v-if="user"
          to="/meal-planner"
          class="self-center text-white hover:text-gray-300"
          aria-label="Go to Meal Planner"
        >
          Meal Planner
        </router-link> -->
        <!-- Toggle between Login and Logout button -->
        <button
          v-if="user"
          @click="logout"
          class="text-white hover:text-gray-300 bg-red-500 px-3 py-1 rounded"
          aria-label="Logout"
        >
          Logout
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router' // Import useRouter
import { useAuthStore } from '../stores/auth'

export default {
  name: 'AppHeader',
  setup() {
    const authStore = useAuthStore()
    const router = useRouter()

    const user = computed(() => authStore.user)

    const logout = async () => {
      await authStore.signOut()
      router.push('/').catch((err) => {
        console.error('Failed to navigate:', err)
      })
    }

    // Make sure to return logout here, not signOut directly from authStore
    return {
      user,
      logout // Corrected to match the function name
    }
  }
}
</script>

<style scoped></style>
