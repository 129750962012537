import './assets/main.css'
import { createApp, onErrorCaptured } from 'vue'
import { createPinia } from 'pinia'
import mitt from 'mitt'

import App from './App.vue'
import router from './router'
import { useAuthStore } from './stores/auth'
import piniaPersist from 'pinia-plugin-persist'

import ErrorTooltip from './components/ErrorTooltip.vue'
// import * as Sentry from '@sentry/vue'

const emitter = mitt()

const app = createApp(App)

// Register ErrorTooltip component globally
app.component('ErrorTooltip', ErrorTooltip)

// Setup Pinia with persistence
const pinia = createPinia()
pinia.use(piniaPersist)
app.use(pinia)

function setupGlobalErrorHandler(app) {
  app.config.globalProperties.$emitter = emitter
  app.provide('emitter', emitter)

  // Global error handler: captures all unhandled errors
  app.config.errorHandler = (err, instance, info) => {
    emitter.emit('error', { err, instance, info })
    console.error(`Error: ${info}`, err)
  }
}

setupGlobalErrorHandler(app)

const authStore = useAuthStore(pinia)
authStore.initAuth()

// Sentry.init({
//   app,
//   dsn: 'https://1c99e3b09ea3652cd52dea72790394bc@o4507260745809920.ingest.us.sentry.io/4507260747120640',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

app.use(router)

app.mount('#app')
