import { defineStore } from 'pinia'
import db from '../firebase/db'
import { doc, setDoc, collection, getDoc, query, getDocs, deleteDoc } from 'firebase/firestore'

export const useRecipesStore = defineStore('recipes', {
  state: () => ({
    trendingRecipes: [],
    fullRecipe: null,
    error: null,
    mealPlan: null
  }),
  actions: {
    async getTrendingRecipes() {
      const localStorageKey = 'trendingRecipes'
      const cachedData = localStorage.getItem(localStorageKey)

      if (cachedData) {
        const { expiry, recipes } = JSON.parse(cachedData)
        if (expiry > Date.now()) {
          this.trendingRecipes = recipes
          return
        }
      }

      const url =
        'https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/random?number=30'
      const headers = {
        'X-RapidAPI-Key': 'bf41dc6748msh3ecad1a84cd8ee0p19f16fjsn34acc112c749',
        'X-RapidAPI-Host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com'
      }

      try {
        const response = await fetch(url, { method: 'GET', headers: headers })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        this.trendingRecipes = data.recipes

        localStorage.setItem(
          localStorageKey,
          JSON.stringify({
            expiry: Date.now() + 10800000, // 3 hours
            recipes: this.trendingRecipes
          })
        )
      } catch (error) {
        console.error('Error fetching trending recipes:', error)
        this.error = 'Error fetching trending recipes'
      }
    },
    async fetchFullRecipe(recipeId) {
      if (!recipeId) {
        this.error = 'Recipe ID is missing'
        return
      }

      const url = `https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/${recipeId}/information`
      const headers = {
        'X-RapidAPI-Key': 'bf41dc6748msh3ecad1a84cd8ee0p19f16fjsn34acc112c749',
        'X-RapidAPI-Host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com'
      }

      try {
        const response = await fetch(url, { method: 'GET', headers: headers })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        const holder = this.formatsRecipe(data)
        this.fullRecipe = holder
        // console.log('Full recipe details:', this.fullRecipe)
      } catch (error) {
        console.error('Error fetching full recipe details:', error)
        this.error = 'An error occurred while fetching recipe details'
      }
    },
    async saveRecipe(userId, recipe) {
      recipe = JSON.parse(JSON.stringify(recipe))

      if (!userId || !recipe) {
        console.error('Missing userId or recipe')
        this.error = 'Missing data to save recipe'
        return
      }

      if (!recipe.id) {
        console.error('Missing recipe ID')
        this.error = 'Missing recipe ID to save recipe'
        return
      }

      try {
        console.log(`Checking recipe with ID: ${recipe.id}`)

        // Reference to the user's recipes collection
        const userRecipesCollectionRef = collection(db, 'users', userId, 'recipes')
        // Create a document reference with the recipe's ID as the document name
        const recipeDocRef = doc(userRecipesCollectionRef, recipe.id.toString())

        // Attempt to retrieve the existing recipe document
        const docSnap = await getDoc(recipeDocRef)

        if (docSnap.exists()) {
          console.log('Recipe already exists, deleting')
          this.removeRecipe(userId, recipe.id)
          // this.error = 'Recipe already exists and will not be overwritten.'
          return
        } else {
          // Save the recipe document since it does not exist
          await setDoc(recipeDocRef, recipe)
          console.log('Recipe saved successfully')
        }
      } catch (error) {
        console.error('Error saving the recipe:', error)
        // this.error = 'An error occurred while saving the recipe'
      }
    },
    async fetchUserRecipes(userId) {
      if (!userId) {
        console.error('Missing userId')
        this.error = 'Missing userId to fetch recipes'
        return
      }

      try {
        const userRecipesCollectionRef = collection(db, 'users', String(userId), 'recipes')

        const q = query(userRecipesCollectionRef) // Create a query against the collection

        const querySnapshot = await getDocs(q)
        this.userRecipes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))

        console.log('User recipes fetched successfully')
      } catch (error) {
        console.error('Error fetching user recipes:', error)
        this.error = 'An error occurred while fetching user recipes'
      }
    },
    async removeRecipe(userId, recipeId) {
      if (!userId || !recipeId) {
        console.error('Missing userId or recipeId')
        this.error = 'Missing userId or recipeId to remove recipe'
        return
      }

      try {
        const userRecipesCollectionRef = collection(db, 'users', userId, 'recipes')
        const recipeDocRef = doc(userRecipesCollectionRef, recipeId.toString())

        // Attempt to retrieve the existing recipe document to ensure it exists
        const docSnap = await getDoc(recipeDocRef)

        if (docSnap.exists()) {
          await deleteDoc(recipeDocRef)
          console.log('Recipe removed successfully')
        } else {
          console.log('No recipe found to remove')
          this.error = 'No recipe found to remove'
        }
      } catch (error) {
        console.error('Error removing the recipe:', error)
        this.error = 'An error occurred while removing the recipe'
      }
    },
    async saveMealPlan(userId, date, recipes) {
      if (!userId || !date) {
        console.error('Missing userId or date')
        this.error = 'Missing userId or date to save meal plan'
        return
      }

      try {
        const userMealPlanCollectionRef = collection(db, 'users', userId, 'mealplans', date, date)
        const MealPlanDocRef = doc(userMealPlanCollectionRef, date.toString())
        await setDoc(MealPlanDocRef, recipes)
        console.log('Meal Plan saved successfully')
        return true
      } catch (error) {
        console.error('Error Saving Meal Plan', error)
        this.error = 'An error occurred while Saving Meal Plan'
        return false
      }
    },
    // TODO: Update this function name to be more clear in related to its return
    async getMealPlanMeals(userId, date) {
      if (!userId || !date) {
        console.error('Missing parameters')
        this.error = 'Missing parameters required to fetch meal plans'
        return
      }

      try {
        const mealPlanDocRef = doc(db, 'users', userId, 'mealplans', date, date, date)
        const docSnap = await getDoc(mealPlanDocRef)

        if (!docSnap.exists()) {
          console.log('No meal plans found')
          return null
        } else {
          // const results = this.formatsMealPlans(docSnap.data())
          const results = docSnap.data()

          console.log(results)
          return results
        }
      } catch (error) {
        console.error('Error fetching user meal plans:', error)
        this.error = 'An error occurred while fetching user meal plans'
      }
    },
    formatsRecipe(recipe) {
      class Recipe {
        constructor(recipe) {
          this.id = recipe.id
          this.title = recipe.title
          this.image = recipe.image
          this.sourceLink = recipe.sourceUrl
          this.sourceName = recipe.sourceName
          this.summary = this.removeHtml(recipe.summary)
          this.instructions = recipe.analyzedInstructions
          this.time = recipe.readyInMinutes
          this.ingredients = recipe.extendedIngredients
        }

        removeHtml(html) {
          return html.replace(/<[^>]*>/g, '')
        }
      }

      const formattedRecipe = new Recipe(recipe)
      return formattedRecipe
    },
    formatsMealPlans(mealPlan) {
      class Plan {
        constructor(mealPlan) {
          this.meals = {
            breakfast: {
              meal: 'breakfast',
              id: mealPlan.breakfast.id,
              title: mealPlan.breakfast.title,
              image: mealPlan.breakfast.image,
              sourceLink: mealPlan.breakfast.sourceUrl,
              sourceName: mealPlan.breakfast.sourceName,
              summary: mealPlan.breakfast.summary,
              instructions: mealPlan.breakfast.analyzedInstructions,
              time: mealPlan.breakfast.readyInMinutes,
              ingredients: mealPlan.breakfast.extendedIngredients
            },
            lunch: {
              meal: 'lunch',
              id: mealPlan.lunch.id,
              title: mealPlan.lunch.title,
              image: mealPlan.lunch.image,
              sourceLink: mealPlan.lunch.sourceUrl,
              sourceName: mealPlan.lunch.sourceName,
              summary: mealPlan.lunch.summary,
              instructions: mealPlan.lunch.analyzedInstructions,
              time: mealPlan.lunch.readyInMinutes,
              ingredients: mealPlan.lunch.extendedIngredients
            },
            snacks: {
              meal: 'snack',
              id: mealPlan.snacks.id,
              title: mealPlan.snacks.title,
              image: mealPlan.snacks.image,
              sourceLink: mealPlan.snacks.sourceUrl,
              sourceName: mealPlan.snacks.sourceName,
              summary: mealPlan.snacks.summary,
              instructions: mealPlan.snacks.analyzedInstructions,
              time: mealPlan.snacks.readyInMinutes,
              ingredients: mealPlan.snacks.extendedIngredients
            },
            dinner: {
              meal: 'dinner',
              id: mealPlan.dinner.id,
              title: mealPlan.dinner.title,
              image: mealPlan.dinner.image,
              sourceLink: mealPlan.dinner.sourceUrl,
              sourceName: mealPlan.dinner.sourceName,
              summary: mealPlan.dinner.summary,
              instructions: mealPlan.dinner.analyzedInstructions,
              time: mealPlan.dinner.readyInMinutes,
              ingredients: mealPlan.dinner.extendedIngredients
            },
            sides: {
              meal: 'side',
              id: mealPlan.sides.id,
              title: mealPlan.sides.title,
              image: mealPlan.sides.image,
              sourceLink: mealPlan.sides.sourceUrl,
              sourceName: mealPlan.sides.sourceName,
              summary: mealPlan.sides.summary,
              instructions: mealPlan.sides.analyzedInstructions,
              time: mealPlan.sides.readyInMinutes,
              ingredients: mealPlan.sides.extendedIngredients
            },
            dessert: {
              meal: 'dessert',
              id: mealPlan.dessert.id,
              title: mealPlan.dessert.title,
              image: mealPlan.dessert.image,
              sourceLink: mealPlan.dessert.sourceUrl,
              sourceName: mealPlan.dessert.sourceName,
              summary: mealPlan.dessert.summary,
              instructions: mealPlan.dessert.analyzedInstructions,
              time: mealPlan.dessert.readyInMinutes,
              ingredients: mealPlan.dessert.extendedIngredients
            }
          }
          this.day = {
            date: mealPlan.date
          }
        }
      }

      return new Plan(mealPlan)
    }
  }
})
