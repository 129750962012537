import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import SavedRecipesView from '@/views/SavedRecipesView.vue'
import MealPlannerView from '@/views/MealPlannerView.vue'
import FullRecipeView from '@/views/FullRecipeView.vue'
import MealPlanDayView from '@/views/MealPlanDayView.vue'

const routes = [
  {
    path: '/recipes',
    name: 'recipes',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: { requiresGuest: true }
  },
  {
    path: '/saved-recipes',
    name: 'SavedRecipes',
    component: SavedRecipesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/meal-planner',
    name: 'MealPlanner',
    component: MealPlannerView,
    meta: { requiresAuth: true }
  },
  {
    path: '/full-recipe/:id/:isSaved',
    name: 'FullRecipe',
    component: FullRecipeView,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/meal-plan-day/:date',
    name: 'MealPlanDayView',
    component: MealPlanDayView,
    props: true,
    meta: { requiresAuth: true }
  }
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest)

  // Check auth state directly from the store
  if (requiresAuth && !authStore.user) {
    next({ name: 'Login' })
  } else if (requiresGuest && authStore.user) {
    next({ name: 'recipes' })
  } else {
    next()
  }
})

export default router
