import { defineStore } from 'pinia'
import { auth } from '../firebase/firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null
  }),
  actions: {
    setUser(user) {
      this.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    setToken(token) {
      this.token = token
      localStorage.setItem('token', token)
    },
    clearAuth() {
      // this.user = null
      // this.token = null
      // localStorage.removeItem('user')
      // localStorage.removeItem('token')
    },
    initAuth() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.setUser(user)
          const token = await user.getIdToken()
          this.setToken(token)
        } else {
          this.clearAuth()
        }
      })
    },
    async signOut() {
      this.user = null
      this.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      return Promise.resolve()
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'auth',
        storage: window.localStorage,
        paths: ['user', 'token']
      }
    ]
  }
})
