import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD6tP-fHrQGh780fDPdVkVbwG-4YezJSPI',
  authDomain: 'recipe-saver-fb267.firebaseapp.com',
  projectId: 'recipe-saver-fb267',
  storageBucket: 'recipe-saver-fb267.appspot.com',
  messagingSenderId: '642740613362',
  appId: '1:642740613362:web:a1b514bf849b487e16206b',
  measurementId: 'G-3MCWTCG1YR'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and export
export const auth = getAuth(app)
