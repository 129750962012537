<script setup>
import { useRouter } from 'vue-router'

const props = defineProps({
  recipe: {
    type: Object,
    required: true
  },
  isSaved: {
    type: Boolean,
    required: false
  }
})

const router = useRouter()

const navigateToRecipe = () => {
  router.push({ name: 'FullRecipe', params: { id: props.recipe.id, isSaved: props.isSaved } })
}
</script>

<template>
  <div
    class="h-[100%]"
    tabindex="0"
    @click="navigateToRecipe"
    :aria-label="`More information about ${props.recipe.title}`"
  >
    <figure>
      <img
        class="rounded-t-lg"
        :src="props.recipe.image"
        :alt="`${props.recipe.title} recipe image`"
      />
    </figure>
    <div class="card-body">
      <h2 class="card-title">{{ props.recipe.title }}</h2>
    </div>
  </div>
</template>
