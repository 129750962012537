<!-- eslint-disable no-unused-vars -->
<template>
  <div class="container mx-auto py-10 md:py-2">
    <!-- Loading and Error handling -->
    <div v-if="isLoading" class="text-center" role="status" aria-live="polite">Loading...</div>
    <div v-if="error" class="text-red-500 text-center" role="alert">{{ error }}</div>
    <div
      v-if="store.fullRecipe && !isLoading"
      class="hero min-h-[55vh] bg-white flex flex-col justify-center flex-wrap space-x-5"
    >
      <div class="md:flex justify-evenly my-5 w-[80vw] mb-12">
        <img
          v-if="store.fullRecipe.image"
          class="rounded-xl"
          :src="store.fullRecipe.image"
          :alt="store.fullRecipe.title || 'Recipe Image'"
        />
        <h1 v-if="store.fullRecipe.title" class="text-5xl font-bold text-center self-center">
          {{ store.fullRecipe.title }}
        </h1>
      </div>
      <div>
        <button
          v-if="store.fullRecipe.title"
          class="btn bg-[#4a6283] text-white bordered capitalize w-fit"
          @click="saveRecipe"
          :aria-label="`Save recipe ${store.fullRecipe.title}`"
        >
          {{ message }}
        </button>
        <button
          v-if="store.fullRecipe.title"
          class="btn bg-[#4a6283] text-white bordered capitalize"
          @click="toggleCookingScreen"
          :aria-label="`Start cooking ${store.fullRecipe.title}`"
        >
          Cook
        </button>
      </div>
    </div>
    <div v-if="store.fullRecipe && !isLoading" class="bg-base-100 shadow-xl">
      <div class="p-8 grid grid-cols-1 md:grid-cols-2">
        <div class="flex flex-col">
          <div class="divider"></div>
          <div>
            <h3 class="text-3xl font-bold mb-2">Ingredients</h3>
            <ol class="p-8 space-y-2 list-outside list-disc">
              <li v-for="ingredient in store.fullRecipe.ingredients" :key="ingredient.id">
                {{ ingredient.original }}
              </li>
            </ol>
          </div>
          <div class="divider"></div>
        </div>
        <div class="flex flex-col">
          <div class="divider"></div>
          <div>
            <h3 class="text-3xl font-bold mb-2">Instructions</h3>
            <ol class="list-decimal p-8 space-y-2 list-outside">
              <li v-for="step in store.fullRecipe.instructions[0].steps" :key="step.number">
                {{ step.step }}
              </li>
            </ol>
          </div>
          <div class="divider mt-auto"></div>
        </div>
        <p>
          <strong>Source: </strong>
          <a
            class="underline"
            :href="store.fullRecipe.source"
            target="_blank"
            rel="noopener noreferrer"
            >{{ store.fullRecipe.sourceName }}</a
          >
        </p>
      </div>
      <div class="card-actions justify-end p-5 mt-5"></div>
    </div>
  </div>

  <!-- Success Alert -->
  <div
    role="alert"
    class="alert alert-success fixed bottom-5 max-w-lg"
    :class="{ hidden: !tooltip }"
    aria-live="assertive"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      viewBox="0 0 24 24"
      class="stroke-current shrink-0 w-6 h-6"
      role="img"
      aria-labelledby="successIconTitle"
    >
      <title id="successIconTitle">Success Icon</title>
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
    <span class="text-white">{{ tooltipMessage }}</span>
  </div>

  <!-- Cooking Instructions Modal -->
  <dialog ref="cookingModal">
    <div class="w-[80vw] mx-auto bg-white h-[100%] p-10">
      <button
        class="block btn bg-[#4a6283] text-white bordered capitalize ml-auto"
        @click="toggleCookingScreen"
        aria-label="Close Cooking Screen"
      >
        Close
      </button>
      <div class="w-3/4 mx-auto">
        <h3 class="text-2xl font-bold text-center">{{ store.fullRecipe?.title || 'Recipe' }}</h3>
        <p class="p-3 text-left w-4/5 mx-auto">
          {{ store.fullRecipe?.summary || 'Summary not available.' }}
        </p>
        <div>
          <h4 class="sr-only">Ingredients</h4>
          <ul>
            <li v-for="ingredient in store.fullRecipe.ingredients" :key="ingredient.nameClean">
              {{ ingredient.nameClean }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useRecipesStore } from '../stores/useRecipesStore'
import { useAuthStore } from '../stores/auth'

const props = defineProps(['id', 'isSaved'])

const cookingScreen = ref(false)
const route = useRoute()
const store = useRecipesStore()
const auth = useAuthStore()
const isLoading = ref(false)
const error = ref(null)
const message = ref('save')
const tooltip = ref(false)
const tooltipMessage = ref('Recipe Saved')

const globalUserId = ref(auth.token)
const cookingModal = ref(null)

onMounted(async () => {
  try {
    isLoading.value = true
    await store.fetchFullRecipe(route.params.id)
  } catch (err) {
    error.value = err.message
  } finally {
    isLoading.value = false
  }
  if (route.params.isSaved === 'true') {
    message.value = 'delete'
  } else {
    message.value = 'save'
  }
})

const saveRecipe = async () => {
  if (!globalUserId.value || !store.fullRecipe) {
    alert('You must be logged in to save a recipe.')
    return
  }
  await store.saveRecipe(globalUserId.value, store.fullRecipe)

  if (message.value === 'delete') {
    tooltipMessage.value = 'Recipe Deleted'
  } else {
    tooltipMessage.value = 'Recipe Saved'
  }

  tooltip.value = true
  setTimeout(() => {
    tooltip.value = false
  }, 2000)

  if (message.value === 'delete') {
    message.value = 'Save'
  } else {
    message.value = 'delete'
  }
}

const toggleCookingScreen = () => {
  cookingScreen.value = !cookingScreen.value
  if (cookingScreen.value) {
    cookingModal.value.showModal()
  } else {
    cookingModal.value.close()
  }
}
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
