<template>
  <div class="flex flex-col space-y-2 justify-center">
    <input
      type="text"
      required
      placeholder="Enter Access Code"
      v-model="accessCode"
      class="input input-bordered w-full max-w-xs"
    />
    <button class="btn btn-primary text-white mt-auto" @click="codeSignIn">
      Sign in with Access Code
    </button>
    <div>
      <button class="btn btn-primary text-white mt-auto w-[100%]" @click="codeRegisterRequest">
        Generate Access Code
      </button>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import db from '@/firebase/db.js'

export default {
  name: 'AccessCodeSignIn',
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const accessCode = ref('')

    const codeSignIn = async () => {
      if (accessCode.value.length < 5) {
        alert('Please Enter 6 Digit Access Code')
        return
      }

      const code = accessCode.value
      const docRef = doc(db, 'codes', code)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data())
        authStore.setUser(code)
        authStore.setToken(code)
        router.push('/recipes')
      } else {
        console.log('User Invalid')
        alert('Code Invalid, please try again')
      }
    }

    const codeRegisterRequest = async () => {
      const code = generateRandomCode(6)
      await setDoc(doc(db, 'codes', code), {
        code: code
      })

      authStore.setUser(code)
      authStore.setToken(code)
      router.push('/recipes')
    }

    function generateRandomCode(length) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let result = ''
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length))
      }

      return result
    }

    return { accessCode, codeSignIn, codeRegisterRequest }
  }
}
</script>
