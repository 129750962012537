<script setup>
import { onMounted, computed } from 'vue'
import { useRecipesStore } from '@/stores/useRecipesStore'
import RecipeCard from '../components/RecipeCard.vue'

const recipesStore = useRecipesStore()

onMounted(() => {
  recipesStore.getTrendingRecipes()
})

const trendingRecipes = computed(() => recipesStore.trendingRecipes)
</script>

<template>
  <div>
    <div
      v-if="recipesStore.error"
      role="alert"
      aria-live="assertive"
      class="text-red-500 text-center"
    >
      {{ recipesStore.error }}
    </div>
    <ul
      v-else
      class="py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4"
      aria-label="Trending Recipes"
    >
      <li
        v-for="recipe in trendingRecipes"
        :key="recipe.id"
        class="self-start card bg-base-100 shadow-xl h-[100%] hover:shadow-2xl hover:shadow-[#1f2937] hover:outline hover:outline-3 hover:outline-[#1f2937] transform transition hover:scale-[1.05] hover:z-[50] hover:col-span-1 cursor-pointer"
        tabindex="0"
        @keypress.enter="openRecipe(recipe)"
      >
        <recipe-card :recipe="recipe" />
      </li>
    </ul>
  </div>
</template>
