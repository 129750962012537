<script setup>
import { onMounted, ref } from 'vue'
import { useRecipesStore } from '../stores/useRecipesStore.js'
import { useAuthStore } from '../stores/auth'
import RecipeCard from '@/components/RecipeCard.vue'

const recipesStore = useRecipesStore()
const auth = useAuthStore()
const globalUserId = ref(auth.user)

onMounted(async () => {
  await recipesStore.fetchUserRecipes(globalUserId.value)
})
</script>
<template>
  <div>
    <div
      v-if="recipesStore.error"
      role="alert"
      aria-live="assertive"
      class="text-red-500 text-center"
    >
      {{ recipesStore.error }}
    </div>
    <ul
      v-else
      class="py-8 grid grid-cols-1 gap-5 lg:grid-cols-4 xl:grid-cols-5"
      aria-label="User Recipes"
    >
      <li
        class="self-start card bg-base-100 shadow-xl h-[100%] hover:shadow-2xl hover:shadow-[#1f2937] hover:outline hover:outline-3 hover:outline-[#1f2937] transform transition hover:scale-[1.05] hover:z-[50] hover:col-span-1 cursor-pointer"
        v-for="recipe in recipesStore.userRecipes"
        :key="recipe.id"
        tabindex="0"
        @keypress.enter="openRecipe(recipe)"
        @click="openRecipe(recipe)"
        role="button"
        aria-label="View recipe for {{ recipe.title }}"
      >
        <recipe-card :recipe="recipe" :isSaved="true" />
      </li>
    </ul>
  </div>
</template>
