<template>
  <div v-if="visible" class="error-tooltip">
    <p>{{ errorMessage }}</p>
    <button @click="hide">Close</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { inject } from 'vue'

const emitter = inject('emitter')
const visible = ref(false)
const errorMessage = ref('')

function hide() {
  visible.value = false
}


emitter.on('error', ({ err }) => {
  errorMessage.value = err.message
  visible.value = true

  setTimeout(() => {
      hide()
    }, 3000)
})


</script>

<style scoped>
.error-tooltip {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
}
</style>
