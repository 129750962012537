<template>
  <div class="card bg-blue-50 overflow-hidden">
    <figure>
      <img
        :src="
          props.meal
            ? props.meal.image ||
              'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg'
            : 'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg'
        "
        alt="Recipe Image"
      />
    </figure>
    <div class="card-body">
      <p class="capitalize text-xl">
        {{ props.meal.meal }}
      </p>
      <span class="underline decoration-slate-900 block h-[1px] bg-blue-200"></span>
      <h3 class="font-bold text-md">
        {{ props.meal ? props.meal.title || 'No Title Available' : 'No Recipe Selected' }}
      </h3>

      <div class="card-actions max-w-md">
        <select
          class="select block w-full max-w-[300px]"
        >
          <option default value="null">No Recipe</option>
          <option v-for="recipe in recipesStore.userRecipes" :key="recipe.id" :value="recipe.id">
            {{ recipe.title }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref , watch } from 'vue';

import { useRecipesStore } from '@/stores/useRecipesStore'

import { useAuthStore } from '@/stores/auth'
const recipesStore = useRecipesStore()

const authStore = useAuthStore()
const globalUserId = ref(authStore.user)

const props = defineProps({
  meal: {
    type: Object,
    required: true
  }
})

watch(
  async () => {
    await recipesStore.fetchUserRecipes(globalUserId.value)
  }
)

// TODO: ADD LOGIC TO UPDATE / add meal plans. I am thinking of just having a PLUS button to add new meals 
// Steps
// 1. Create a const to hold the value of the plan recipe
// 2. Create a function that on change updates the said const to reflect the new recipe.
// 3. When the const is updated to reflect the new recipe. the recipe image should change.
// 4. When the user clicks "save plan" each recipe card is selected and saved into firestore.



</script>
