<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useRecipesStore } from '@/stores/useRecipesStore'
import { useAuthStore } from '@/stores/auth'
import MealPlanCard from '@/components/MealPlanCard.vue'
import NewMealPlanIcon from '@/components/NewMealPlanIcon.vue'
// import ShoppingList from '@/components/ShoppingList.vue'

const recipesStore = useRecipesStore()
const authStore = useAuthStore()
const globalUserId = ref(authStore.user)
// const userRecipes = ref({})
const route = useRoute()
const date = ref(route.params.date)
const isActive = ref(false)
const resultMessage = ref('')
const selectedRecipes = ref({
  breakfast: null,
  lunch: null,
  dinner: null,
  snacks: null,
  dessert: null,
  sides: null,
  date: date.value
})

onMounted(async () => {
  await recipesStore.fetchUserRecipes(globalUserId.value)

  selectedRecipes.value = await recipesStore.getMealPlanMeals(globalUserId.value, date.value)

  if (selectedRecipes.value === null) {
    selectedRecipes.value = {
      breakfast: null,
      lunch: null,
      dinner: null,
      snacks: null,
      dessert: null,
      sides: null,
      date: date.value
    }
  }
})
watch(
  () => route.params.date,
  async () => {
    date.value = route.params.date
    await recipesStore.fetchUserRecipes(globalUserId.value)
  }
)

function getMeals(globalUserId, selectedRecipes, recipes) {
  const result = recipesStore.saveMealPlan(globalUserId, selectedRecipes, recipes)

  if (result) {
    resultMessage.value = 'Meal Plan Saved'
    isActive.value = true
    setTimeout(() => {
      isActive.value = false
    }, 2000)
  } else {
    resultMessage.value = 'Error saving meal plan'
    isActive.value = true
    setTimeout(() => {
      isActive.value = false
    }, 2000)
  }
}

function updatesSelectedRecipes(meal, recipe) {
  selectedRecipes.value[meal] = JSON.parse(JSON.stringify(recipe))
}
</script>

<template>
  <main>
    <div class="flex justify-between py-4">
      <h2 class="text-center text-4xl pt-4">{{ date }}</h2>
      <button
        @click="getMeals(globalUserId, selectedRecipes.date, selectedRecipes)"
        class="btn btn-success self-center text-gray-100"
      >
        Save Plan
      </button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-10 py-10">
      <MealPlanCard v-for="meal in selectedRecipes.meals" :key="meal" :meal="meal" />

      <NewMealPlanIcon @updatesSelectedRecipes="updatesSelectedRecipes" />
    </div>
    <!-- <ShoppingList :recipes="selectedRecipes" /> -->
  </main>

  <div
    role="alert"
    :class="{ fixed: isActive, hidden: !isActive }"
    class="alert alert-success bottom-10 w-fit right-10"
  >
    <span class="text-white">{{ resultMessage }}</span>
    <span class="text-white">X</span>
  </div>
</template>
