import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyD6tP-fHrQGh780fDPdVkVbwG-4YezJSPI',
  authDomain: 'recipe-saver-fb267.firebaseapp.com',
  projectId: 'recipe-saver-fb267',
  storageBucket: 'recipe-saver-fb267.appspot.com',
  messagingSenderId: '642740613362',
  appId: '1:642740613362:web:a1b514bf849b487e16206b',
  measurementId: 'G-3MCWTCG1YR'
}

// Initialize Firebase & Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export default db
